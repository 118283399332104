<template>
  <a-tag :color="color">{{ $t(`ip_subnet_scan_status.${status}`) }}</a-tag>
</template>

<script>
import { blueColor, greenColor } from '@/utils/const'

export default {
  name: 'ScanStatusTag',
  props: {
    status: {
      type: String,
      default: 'unscanned'
    }
  },
  computed: {
    color () {
      switch (this.status) {
        case 'scanning':
          return blueColor
        case 'scanned':
          return greenColor
        default:
          return ''
      }
    }
  }
}
</script>
