<template>
  <a-drawer
    class="detail-drawer"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1200"
    @close="visible = false"
  >
    <a-tabs type="card">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          ref="sliderRow"
          :gutter="24"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <a-col :sm="0" :lg="4">
            <a-anchor
              :wrapper-style="{ background: 'transparent' }"
              :offset-top="16"
              :get-container="() => $refs.sliderRow.$el"
            >
              <a-anchor-link href="#detail" title="基本信息" />
              <a-anchor-link href="#ip" title="IP 地址" />
            </a-anchor>
          </a-col>
          <a-col :sm="24" :lg="20">
            <div id="detail">
              <div class="small-module-title">
                <a-icon type="profile"></a-icon>
                <span> 基本信息</span>
              </div>

              <div style="padding: 0 16px">
                <a-descriptions :column="3">
                  <a-descriptions-item label="名称">
                    {{ detail.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="描述">
                    {{ detail.description }}
                  </a-descriptions-item>
                  <a-descriptions-item label="子网掩码">
                    {{ detail.netmask }}
                  </a-descriptions-item>
                  <a-descriptions-item label="子网容量">
                    {{ detail.capacity }}
                  </a-descriptions-item>
                  <a-descriptions-item label="创建时间">
                    {{ detail.created_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="更新时间">
                    {{ detail.updated_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="状态">
                    <scan-status-tag
                      :status="detail.scan_status"
                    ></scan-status-tag>
                  </a-descriptions-item>
                </a-descriptions>
              </div>
            </div>
            <div id="ip" style="margin-top: 8px;">
              <div class="small-module-title">
                <a-icon type="profile"></a-icon>
                <span> IP 地址</span>
              </div>

              <div style="padding: 0 16px">
                <div style="text-align: right; padding: 4px 22px 8px 16px; font-size: 11px; color: rgba(0,0,0,.65)">
                  <a-space size="large">
                    <a-space>
                      <div style="border-radius: 4px; background: #52c41a; font-size: 12px; width: 18px; height: 17px; line-height: 17px; text-align: center;">
                        <source-icon source-type="os" style="color: #fff"></source-icon>
                      </div>
                      <div>已监控，有数据</div>
                    </a-space>
                    <a-space>
                      <div style="border-radius: 4px; background: #d9d9d9; font-size: 12px; width: 18px; height: 17px; line-height: 17px; text-align: center;">
                        <source-icon source-type="os" style="color: #fff"></source-icon>
                      </div>
                      <div>已监控，无数据</div>
                    </a-space>
                    <a-space>
                      <div style="border-radius: 4px; background: #52c41a; font-size: 12px; width: 18px; height: 17px; line-height: 17px; text-align: center;">
                        <span style="color: #fff;">1</span>
                      </div>
                      <div>未监控，有数据</div>
                    </a-space>
                    <a-space>
                      <div style="border-radius: 4px; background: #d9d9d9; font-size: 12px; width: 18px; height: 17px; line-height: 17px; text-align: center;">
                        <span style="color: #fff;">1</span>
                      </div>
                      <div>未监控，无数据</div>
                    </a-space>
                  </a-space>
                </div>
                <ip-address-list :subnet-id="detail.id"></ip-address-list>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import { getIPSubnet } from '@/api/ip-subnet'
import ScanStatusTag from '@/components/tag/ScanStatusTag'
import IPAddressList from './modules/IPAddressList'
import SourceIcon from '@/components/icon/SourceIcon'

export default {
  name: 'DetailDrawer',
  components: {
    'ip-address-list': IPAddressList,
    ScanStatusTag,
    SourceIcon
  },
  data () {
    return {
      visible: false,
      detail: {
        id: '',
        name: '',
        description: '',
        netmask: '',
        capacity: 0,
        created_at: '',
        updated_at: '',
        scan_status: false
      }
    }
  },
  methods: {
    show (id) {
      getIPSubnet(id)
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
    }
  }
}
</script>

<style lang="less">
.detail-drawer {
  .small-module-title{
    height: 35px;
    background: #E4F1FF;
    border-radius: 8px;
    color: #096DD9;
    padding: 0 16px;
    margin-bottom: 16px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

  .button-container {
    text-align: right;
    padding: 0 0 8px 0;

    a {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .active {
      color: #1890ff;
    }
  }

  .unit-card {
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
  }
}
</style>
