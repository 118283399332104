<template>
  <a-spin :spinning="spinning" style="min-height: 320px">
    <div
      v-for="ipAddress in ipAddresses"
      :key="ipAddress.id"
      style="width: 84px; height: 32px; display: inline-block;"
    >
      <a-tooltip>
        <template slot="title">
          {{ ipAddress.source ? ipAddress.source.name : (ipAddress.hostname ? ipAddress.hostname : '未知') }}
        </template>
        <a-card
          :bordered="false"
          :body-style="getBodyStyle(ipAddress.online, ipAddress.allocated)"
          @click="showDetail(ipAddress)"
        >
          <a-space :size="4">
            <source-icon
              v-if="ipAddress.source"
              :source-type="ipAddress.source_type"
              style="color: #fff"
            ></source-icon>
            <div style="color: #ffffff; user-select: none; overflow: hidden;">
              {{ getDisplayName(ipAddress) }}
            </div>
          </a-space>
        </a-card>
      </a-tooltip>
    </div>

    <network-device-drawer ref="networkDeviceDrawer"></network-device-drawer>
    <terminal-drawer ref="terminalDrawer"></terminal-drawer>
    <storage-drawer ref="storageDrawer"></storage-drawer>
    <server-drawer ref="serverDrawer"></server-drawer>
    <os-drawer ref="osDrawer"></os-drawer>
    <hypervisor-drawer ref="hypervisorDrawer"></hypervisor-drawer>
  </a-spin>
</template>

<script>
import { getIPSubnetIPAddressList } from '@/api/ip-subnet'
import SourceIcon from '@/components/icon/SourceIcon.vue'
import { grayColor, greenColor } from '@/utils/const'
import HypervisorDrawer from '@/components/drawer/HypervisorDrawer'
import NetworkDeviceDrawer from '@/components/drawer/NetworkDeviceDrawer'
import TerminalDrawer from '@/components/drawer/TerminalDrawer/index.vue'
import OSDrawer from '@/components/drawer/OSDrawer'
import ServerDrawer from '@/components/drawer/ServerDrawer'
import StorageDrawer from '@/components/drawer/StorageDrawer'

export default {
  name: 'IPAddressList',
  components: {
    SourceIcon,
    HypervisorDrawer,
    NetworkDeviceDrawer,
    'os-drawer': OSDrawer,
    ServerDrawer,
    StorageDrawer,
    TerminalDrawer
  },
  props: {
    subnetId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      spinning: false,
      ipAddresses: []
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning = true
      getIPSubnetIPAddressList(this.subnetId)
        .then(res => {
          this.ipAddresses = res.data.data
        })
        .finally(() => {
          this.spinning = false
        })
    },
    getBodyStyle (online, allocated) {
      return {
        background: online ? greenColor : grayColor,
        cursor: online && allocated ? 'pointer' : 'default',
        padding: 0,
        lineHeight: '32px',
        textAlign: 'center',
        fontSize: '12px'
      }
    },
    getDisplayName (ipAddress) {
      if (ipAddress.source) {
        return ipAddress.source.name
      } else if (ipAddress.hostname) {
        return ipAddress.hostname
      } else {
        return ipAddress.name.split('.')[3]
      }
    },
    showDetail (ipAddress) {
      if (ipAddress.online) {
        switch (ipAddress.source_type) {
          case 'network_device':
            this.$refs.networkDeviceDrawer.show(ipAddress.source_id)
            break
          case 'terminal_device':
            this.$refs.terminalDrawer.show(ipAddress.source_id)
            break
          case 'storage':
            this.$refs.storageDrawer.show(ipAddress.source_id)
            break
          case 'server':
            this.$refs.serverDrawer.show(ipAddress.source_id)
            break
          case 'os':
            this.$refs.osDrawer.show(ipAddress.source_id)
            break
          case 'hypervisor':
            this.$refs.hypervisorDrawer.show(ipAddress.source_id)
            break
        }
      }
    }
  }
}
</script>

<style lang="less">
.ant-badge-status-dot {
  width: 12px;
  height: 12px;
  border-radius: 0;
}
</style>
