<template>
  <a-modal
    class="select-scan-modal"
    :visible="visible"
    title="扫描策略"
    :width="600"
    :destroy-on-close=true
    :bodyStyle="{ paddingBottom: 0 }"
    @cancel="reset"
    @ok="handleOk">
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="方式" prop="scanMethods">
        <a-checkbox-group v-model="form.methods">
          <a-checkbox :disabled="true" value="source">资源匹配</a-checkbox>
          <a-checkbox value="ping">Ping</a-checkbox>
          <a-checkbox value="nmap">NMAP</a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { scanIPSubnet } from '@/api/ip-subnet'

export default {
  name: 'ScanModal',
  data () {
    return {
      visible: false,
      id: '',
      form: {
        methods: ['source']
      },
      rules: {
        methods: [
          {
            message: '请至少选择一种扫描方式',
            required: true,
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    show (id) {
      this.id = id
      this.visible = true
    },
    reset () {
      this.form = {
        methods: ['source']
      }
      this.visible = false
    },
    handleOk () {
      const params = {
        id: this.id,
        methods: this.form.methods
      }
      scanIPSubnet(params).then(res => {
        this.$message.success(res.message)
        this.$emit('ok')
        this.reset()
      })
    }
  }
}
</script>
