<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="profile"></a-icon>
          <span>子网管理</span>
        </a-space>
      </template>
      <span>子网管理包含 IP 子网、IP 地址等。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :getTableDataFunc="getTableDataFunc"
        :columnDefaultSelected="columnDefaultSelected"
        :columnsAll="columnsAll"
        :toolsList="['setting', 'reload', 'search', 'leftTool']"
        :refresh="refresh"
        :tableOperationWidth="240"
         :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
      >
        <!-- 工具栏 -->
        <template #leftTool>
          <create-button
            v-permission="{action: 'base.ip_subnet.create', effect: 'disabled'}"
            @click="$refs.inputDrawer.show()"
          ></create-button>
        </template>
        <!-- 表格自定义列 -->
        <template #name="{ slotProps }">
          <a-tooltip placement="topLeft" :title="slotProps.text">
            <a @click="$refs.detailDrawer.show(slotProps.record.id)">
              {{ slotProps.text }}
            </a>
          </a-tooltip>
        </template>
        <template #scanStatus="{ slotProps }">
          <scan-status-tag :status="slotProps.text"></scan-status-tag>
        </template>
        <template #used="{ slotProps }">
          <usage-progress
            :percent="
              (
                (slotProps.record.used / slotProps.record.capacity) *
                100
              ).toFixed(2) * 1
            "
          ></usage-progress>
        </template>
        <template #operation="{ slotProps }">
          <a-button
            :disabled="slotProps.record.scan_status === 'scanning'"
            icon="scan"
            size="small"
            type="link"
            @click="$refs.scanModal.show(slotProps.record.id)"
          >
            扫描
          </a-button>
          <edit-button
          v-permission="{action: 'base.ip_subnet.update', effect: 'disabled'}"
            @click="$refs.inputDrawer.show(slotProps.record.id)"
          ></edit-button>
          <delete-button
          v-permission="{action: 'base.ip_subnet.delete', effect: 'disabled'}"
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>
    </a-card>

    <detail-drawer ref="detailDrawer"></detail-drawer>
    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
    <scan-modal ref="scanModal" @ok="refresh=!refresh"></scan-modal>
  </div>
</template>

<script>
import { deleteIPSubnet, getIPSubnetList } from '@/api/ip-subnet'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import UsageProgress from '@/components/progress/UsageProgress'
import ScanStatusTag from '@/components/tag/ScanStatusTag'
import { ipSubnetScanStatuses } from '@/utils/const'
import DetailDrawer from './modules/DetailDrawer'
import InputDrawer from './modules/InputDrawer'
import ScanModal from './modules/ScanModal'

export default {
  name: 'IPSubnet',
  components: {
    DeleteButton,
    DetailDrawer,
    EditButton,
    InputDrawer,
    ListLayout,
    ScanModal,
    ScanStatusTag,
    UsageProgress,
    CreateButton
  },
  data () {
    return {
      getTableDataFunc: getIPSubnetList,
      form: {
        name: '',
        scanStatus: undefined,
        page: 1,
        pageSize: 10
      },
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'netmask',
          title: '子网掩码',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'netmask'
          }
        },
        {
          dataIndex: 'capacity',
          title: '子网容量',
          width: 100
        },
        {
          dataIndex: 'used',
          title: '使用率',
          width: 200,
          ellipsis: false,
          scopedSlots: {
            customRender: 'used'
          }
        },
        {
          dataIndex: 'description',
          title: '描述',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          dataIndex: 'scan_status',
          title: '状态',
          align: 'center',
          width: 100,
          scopedSlots: {
            customRender: 'scanStatus'
          },
          filters: ipSubnetScanStatuses.map(value => {
            return {
              text: this.$t(`ip_subnet_scan_status.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'description',
        'netmask',
        'capacity',
        'used',
        'scan_status'
      ],
      refresh: false,
      tableSelectData: []
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteIPSubnet(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      }
      this.refresh = !this.refresh
    }
  }
}
</script>
