import request from '@/utils/request'

const urlPrefix = '/ip-subnets'

export function getIPSubnet (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateIPSubnet (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteIPSubnet (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getIPSubnetIPAddressList (id) {
  return request.get(`${urlPrefix}/${id}/ip-addresses`)
}

export function getIPSubnetList (params) {
  return request.get(urlPrefix, { params })
}

export function createIPSubnet (data) {
  return request.post(urlPrefix, data)
}

export function scanIPSubnet (data) {
  return request.post(`${urlPrefix}/scan`, data)
}
