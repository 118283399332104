var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{staticClass:"select-scan-modal",attrs:{"visible":_vm.visible,"title":"扫描策略","width":600,"destroy-on-close":true,"bodyStyle":{ paddingBottom: 0 }},on:{"cancel":_vm.reset,"ok":_vm.handleOk}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{attrs:{"label":"方式","prop":"scanMethods"}},[_c('a-checkbox-group',{model:{value:(_vm.form.methods),callback:function ($$v) {_vm.$set(_vm.form, "methods", $$v)},expression:"form.methods"}},[_c('a-checkbox',{attrs:{"disabled":true,"value":"source"}},[_vm._v("资源匹配")]),_c('a-checkbox',{attrs:{"value":"ping"}},[_vm._v("Ping")]),_c('a-checkbox',{attrs:{"value":"nmap"}},[_vm._v("NMAP")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }