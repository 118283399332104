<template>
  <a-drawer
    class="detail-drawer"
    :closable="false"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1080"
    @close="closeDrawer"
  >
    <a-tabs v-model="tabKey">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          v-if="detail.id"
          :gutter="16"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <!-- 健康仪表盘 -->
          <a-col :span="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div
                style="
                  height: 60px;
                  margin-bottom: 8px;
                  display: grid;
                  grid-template-rows: 1fr 1fr;
                  justify-items: center;
                  grid-template-columns: 2fr 3fr;
                  line-height: 30px;
                "
              >
                <div style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">
                  资源状态
                </div>
                <source-status-tag :status="detail.status"></source-status-tag>
                <div style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">
                  监控状态
                </div>
                <div>
                  <monitor-status-tag
                    v-for="item in monitorStatuses"
                    :key="item.name"
                    :status="item.value"
                    :name="item.name"
                  ></monitor-status-tag>
                </div>
              </div>
              <dashboard-chart
                v-if="showScoreChart"
                chart-id="overview-health-chart"
                :height="110"
                :chart-data="dashboardData"
              ></dashboard-chart>
            </div>
          </a-col>
          <a-col :span="8" style="margin-bottom: 16px">
            <div class="module-container" style="height: 208px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">监控信息</div>
              <a-descriptions
                :column="1"
                class="description-overflow-hidden description-item-12px"
              >
                <a-descriptions-item label="ID">
                  <content-tooltip
                    v-if="detail.id"
                    :title="detail.id.replaceAll('-', '')"
                    :body="detail.id.replaceAll('-', '')"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
                <a-descriptions-item label="所属群组">
                  <a-tag v-for="group in detail.groups" :key="group.id">
                    {{ group.name }}
                  </a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="IP 地址">
                  <content-tooltip
                    v-if="detail.monitor_address"
                    :title="detail.monitor_address"
                    :body="detail.monitor_address"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
                <a-descriptions-item label="监控频率">
                  <content-tooltip
                    v-if="detail.delay"
                    :title="`${detail.delay} 秒`"
                    :body="`${detail.delay} 秒`"
                  ></content-tooltip>
                  <span v-else>-</span>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </a-col>
          <!-- 告警情况 -->
          <a-col :span="24" style="margin-bottom: 16px">
            <div class="module-container" style="height: 320px">
              <div class="module-header-fs14" style="margin-bottom: 14px;">告警情况</div>
              <overview-alerts :dataSource="alertsData" :loading="loading"></overview-alerts>
            </div>
          </a-col>
          <a-col :span="24">
            <div class="module-container">
              <div class="software-page">
                <div style="margin-bottom: 16px">
                  <datetime-range
                    :allow-clear="false"
                    :default-value="datetimeRange"
                    @ok="
                      v => {
                        datetimeRange = v
                      }
                    "
                  ></datetime-range>
                </div>
                <a-row :gutter="16">
                  <a-col
                    v-for="item in monitorItems"
                    :key="item.key"
                    :span="12"
                    style="margin-bottom: 16px;"
                  >
                    <monitor-chart-card
                      :datetime-range="datetimeRange"
                      :history-func="historyFunc"
                      :item="item"
                      :source-id="detail.id"
                    ></monitor-chart-card>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>

      <a-tab-pane key="rule" tab="告警规则">
        <div
          style="
            padding: 0 16px;
            flex-flow: nowrap;
            height: calc(100vh - 80px);
            overflow: auto;
          "
        >
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="alert" tab="历史告警">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <alert-table source-type="network_device" :source-id="detail.id"></alert-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import {
  getTerminalDevice,
  getTerminalDeviceAlertList,
  getTerminalDeviceHealthScore,
  getTerminalDeviceMonitorHistory,
  getTerminalDeviceMonitorStatusList,
  getTerminalDeviceMonitorTriggerList,
  updateTerminalDeviceMonitorTrigger
} from '@/api/terminal-device'
import ContentTooltip from '@/components/ContentTooltip'
import DatetimeRange from '@/components/DatetimeRange'
import MonitorChartCard from '@/components/card/MonitorChartCard'
import DashboardChart from '@/components/chart/DashboardChart'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable'
import OverviewAlerts from '@/components/source-detail-page/OverviewAlerts'
import AlertTable from '@/components/table/AlertTable.vue'

export default {
  name: 'TerminalDrawer',
  components: {
    AlertTable,
    ContentTooltip,
    DashboardChart,
    DatetimeRange,
    MonitorChartCard,
    MonitorStatusTag,
    MonitorTriggerTable,
    OverviewAlerts,
    SourceStatusTag
  },
  data () {
    return {
      visible: false,
      tabKey: 'overview',
      monitorStatuses: [],
      getTriggerFunc: getTerminalDeviceMonitorTriggerList,
      updateFunc: updateTerminalDeviceMonitorTrigger,
      historyFunc: getTerminalDeviceMonitorHistory,
      dashboardData: [],
      detail: {
        id: '',
        name: '',
        device_type: undefined,
        monitor_address: '',
        delay: 60,
        location: '',
        groups: [],
        status: undefined,
        created_at: '',
        updated_at: ''
      },
      alertsData: [],
      loading: false,
      showScoreChart: false,
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      monitorItems: [
        {
          key: 'icmpping',
          title: 'ICMP Ping'
        },
        {
          key: 'icmppingloss',
          title: 'ICMP Ping 丢包率'
        },
        {
          key: 'icmppingsec',
          title: 'ICMP Ping 延迟'
        }
      ]
    }
  },
  methods: {
    show (id) {
      getTerminalDevice(id)
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
      getTerminalDeviceMonitorStatusList(id).then(res => {
        this.monitorStatuses = res.data.data
      })
      const params = {
        recovered: false,
        source_id: id
      }
      getTerminalDeviceAlertList(params).then(res => {
        this.alertsData = res.data.data
      }).finally(() => {
        this.loading = false
      })
      getTerminalDeviceHealthScore(id).then(res => {
        this.dashboardData = [{ value: res.data }]
      }).finally(() => {
        this.showScoreChart = true
      })
    },
    closeDrawer () {
      this.visible = false
      this.detail = {
        id: '',
        name: '',
        device_type: undefined,
        monitor_address: '',
        delay: 60,
        location: '',
        groups: [],
        status: undefined,
        created_at: '',
        updated_at: ''
      }
      this.tabKey = 'overview'
      this.alertsData = []
      this.monitorStatuses = []
      this.showScoreChart = false
    }
  }
}
</script>

<style lang="less">
.detail-drawer {
  .module-container {
    position: relative;
    background: #fff;
    border-radius: 6px;
    padding: 16px 16px 8px 16px;
    overflow: hidden;
    border: 1px solid #e5e5e5;

    header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
      margin-bottom: 16px;
    }

    .software-page {
      padding: 0 4px;
      .module-container {
        position: relative;
        background: #fff;
        border-radius: 6px;
        padding: 16px 16px 8px 16px;
        margin-right: 1px;
        overflow: hidden;
        border: 1px solid #e5e5e5;

        header {
          font-size: 16px;
          font-weight: 500;
          color: #096dd9;
          line-height: 22px;
        }

        .ant-descriptions-item-content {
          font-size: 14px;
        }
      }
    }
  }

  .health-module-container::before {
    content: '';
    position: absolute;
    width: 1px;
    top: 46px;
    bottom: 26px;
    left: 50%;
    background: #e5e5e5;
  }
}
</style>
