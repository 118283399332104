<template>
  <a-drawer
    class="detail-drawer"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1200"
    @close="() => {
      visible = false
      baseInfoDisplay = false
    }"
  >
    <a-tabs type="card">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          ref="sliderRow"
          :gutter="24"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <a-col :sm="0" :lg="4">
            <a-anchor
              :wrapperStyle="{ background: 'transparent' }"
              :offsetTop="16"
              :getContainer="() => $refs.sliderRow.$el"
            >
              <a-anchor-link href="#detail" title="基本信息" />
              <a-anchor-link
                href="#alert"
                :title="`当前告警 (${count.alert})`"
              />
              <a-anchor-link href="#monitor" title="监控" />
            </a-anchor>
          </a-col>
          <a-col :sm="24" :lg="20">
            <div id="detail">
              <div class="small-module-title">
                <a-icon type="profile"></a-icon>
                <span> 基本信息</span>
              </div>

              <div style="padding: 0 16px">
                <a-descriptions :column="3">
                  <a-descriptions-item label="名称">
                    {{ detail.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="设备类型">
                    {{ $t(`network_device_type.${detail.device_type}`) }}
                  </a-descriptions-item>
                  <a-descriptions-item label="制造商">
                    {{ $t(`hardware_vendor.${detail.vendor}`) }}
                  </a-descriptions-item>
                  <a-descriptions-item label="型号">
                    {{ detail.model }}
                  </a-descriptions-item>
                  <a-descriptions-item label="固件版本">
                    {{ detail.firmware_version }}
                  </a-descriptions-item>
                  <a-descriptions-item label="创建时间">
                    {{ detail.created_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="更新时间">
                    {{ detail.updated_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="所属群组">
                    <a-tag v-for="group in detail.groups" :key="group.id">
                      {{ group.name }}
                    </a-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="状态" v-if="baseInfoDisplay">
                    <source-status-tag
                      :status="detail.status"
                    ></source-status-tag>
                  </a-descriptions-item>
                  <a-descriptions-item label="IP 地址" v-if="baseInfoDisplay">
                    {{ detail.monitor_address }}
                  </a-descriptions-item>
                  <a-descriptions-item label="监控端口" v-if="baseInfoDisplay">
                    {{ detail.monitor_port }}
                  </a-descriptions-item>
                  <a-descriptions-item label="SNMP 版本" v-if="baseInfoDisplay">
                    {{ $t(`snmp_version.${detail.snmp_version}`) }}
                  </a-descriptions-item>
                  <!-- <a-descriptions-item
                    v-if="detail.snmp_version !== 'snmp_v3' && baseInfoDisplay"
                    label="SNMP 团体名"
                  >
                    {{ detail.snmp_community }}
                  </a-descriptions-item> -->
                  <a-descriptions-item label="监控状态" v-if="baseInfoDisplay">
                    <monitor-status-tag
                      v-for="item in monitorStatuses"
                      :key="item.name"
                      :status="item.value"
                      :name="item.name"
                    ></monitor-status-tag>
                  </a-descriptions-item>
                </a-descriptions>
                <div style="text-align: center; font-size: 12px; color: #096DD9; margin-bottom: 16px;">
                  <a @click="baseInfoDisplay = !baseInfoDisplay">
                    <a-icon theme="filled" :type="baseInfoDisplay ? 'caret-up': 'caret-down'"></a-icon>
                  </a>
                </div>
              </div>
            </div>
            <div id="alert">
              <div class="small-module-title">
                <a-icon type="alert"></a-icon>
                <span> 当前告警 ({{ count.alert }})</span>
              </div>

              <div style="padding: 0 16px">
                <div style="height: 24px; margin-bottom: 24px">
                  <severity-radio-group
                    v-model="currentSeverity"
                    style="float: right;"
                  ></severity-radio-group>
                </div>

                <div class="overflow-scroll-hidden" style="max-height: 400px; overflow: scroll">
                  <alert-timeline
                    :severity="currentSeverity"
                    :source-id="detail.id"
                    source-type="network_device"
                    @total="v => (count.alert = v)"
                    style="margin-top: 8px"
                  ></alert-timeline>
                </div>
              </div>
            </div>
            <div id="monitor">
              <div class="small-module-title">
                <a-icon type="line-chart"></a-icon>
                <span> 监控</span>
              </div>
              <div style="padding: 0 16px">
                <div style="margin-bottom: 16px">
                  <datetime-range
                    :allow-clear="false"
                    :default-value="datetimeRange"
                    @ok="
                      v => {
                        datetimeRange = v
                      }
                    "
                  ></datetime-range>
                </div>

                <a-row :gutter="16">
                  <a-col
                    v-for="item in monitorItems"
                    :key="item.key"
                    :sm="24"
                    :lg="12"
                    style="margin-bottom: 16px"
                  >
                    <monitor-chart-card
                      :datetime-range="datetimeRange"
                      :history-func="historyFunc"
                      :item="item"
                      :source-id="detail.id"
                    ></monitor-chart-card>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="networkInterface" tab="网口">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <hardware-network-interface-table
            :data-source="networkInterfaceData"
            :loading="false"
            source-type="network_device"
            :source-id="detail.id"
          ></hardware-network-interface-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="monitor" tab="监控指标">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <monitor-item-table
            :get-func="getItemListFunc"
            :history-func="historyFunc"
            :source-id="detail.id"
          ></monitor-item-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="rule" tab="告警规则">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <monitor-trigger-table
            :get-func="getTriggerFunc"
            :source-id="detail.id"
            :update-func="updateFunc"
          ></monitor-trigger-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="alert" tab="历史告警">
        <div style="padding: 0 16px; flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto">
          <alert-table source-type="network_device" :source-id="detail.id"></alert-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import {
  getNetworkDevice,
  getNetworkDeviceMonitorHistory,
  getNetworkDeviceMonitorItemList,
  getNetworkDeviceMonitorStatusList,
  getNetworkDeviceMonitorTriggerList,
  getNetworkDeviceNetworkInterfaceList,
  updateNetworkDeviceMonitorTrigger
} from '@/api/network-device'
import DatetimeRange from '@/components/DatetimeRange'
import MonitorChartCard from '@/components/card/MonitorChartCard'
import SeverityRadioGroup from '@/components/radio/SeverityRadioGroup'
import MonitorItemTable from '@/components/table/MonitorItemTable'
import MonitorTriggerTable from '@/components/table/MonitorTriggerTable'
import MonitorStatusTag from '@/components/tag/MonitorStatusTag'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
// import NetworkInterfaceTable from '@/components/table/NetworkInterfaceTable'
import HardwareNetworkInterfaceTable from '@/components/table/HardwareNetworkInterfaceTable.vue'
import AlertTable from '@/components/table/AlertTable.vue'

export default {
  name: 'NetworkDeviceDrawer',
  components: {
    AlertTimeline: () => import('@/components/timeline/AlertTimeline'),
    DatetimeRange,
    MonitorChartCard,
    MonitorItemTable,
    MonitorStatusTag,
    MonitorTriggerTable,
    // NetworkInterfaceTable,
    SeverityRadioGroup,
    SourceStatusTag,
    HardwareNetworkInterfaceTable,
    AlertTable
  },
  data () {
    return {
      visible: false,
      baseInfoDisplay: false,
      currentSeverity: 'all',
      detail: {
        id: '',
        name: '',
        monitor_address: '',
        monitor_port: 161,
        device_type: undefined,
        vendor: undefined,
        model: '',
        firmware_version: '',
        serial_number: '',
        snmp_version: 'snmp_v2c',
        snmp_community: '',
        created_at: '',
        updated_at: '',
        groups: [],
        status: undefined
      },
      networkInterfaceData: [],
      monitorStatuses: [],
      datetimeRange: [moment().subtract(6, 'hour'), moment()],
      monitorItems: [
        {
          key: 'icmppingsec',
          title: 'ICMP 延迟 (秒)'
        },
        {
          key: 'icmppingloss',
          title: 'ICMP 丢包率 (%)'
        },
        {
          key: 'system.cpu.util',
          title: 'CPU 使用率 (%)'
        },
        {
          key: 'vm.memory.util',
          title: '内存使用率 (%)'
        }
      ],
      getInterfaceListFunc: getNetworkDeviceNetworkInterfaceList,
      getItemListFunc: getNetworkDeviceMonitorItemList,
      historyFunc: getNetworkDeviceMonitorHistory,
      getTriggerFunc: getNetworkDeviceMonitorTriggerList,
      updateFunc: updateNetworkDeviceMonitorTrigger,
      getInterfaceMonitorListFunc: getNetworkDeviceMonitorHistory,
      count: {
        alert: 0
      }
    }
  },
  methods: {
    show (id) {
      getNetworkDevice(id)
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
      getNetworkDeviceMonitorStatusList(id).then(res => {
        this.monitorStatuses = res.data.data
      })
      this.networkInterfaceData = []
      getNetworkDeviceNetworkInterfaceList(id)
        .then(res => {
          const data = res.data
          if (data && data.data.length) {
            data.data.forEach(item => {
              const obj = {
                id: item.id,
                name: item.name,
                index: item.index,
                interface_type: item.interface_type,
                status: item.status,
                speed: 0,
                net_if_in: 0,
                net_if_out: 0
              }
              this.getKeys(item.index).forEach(keyObj => {
                getNetworkDeviceMonitorItemList(this.detail.id, {
                  key: keyObj.key
                }).then(res1 => {
                  obj[keyObj.dataIndex] = res1.data.data.length
                    ? res1.data.data[0].value
                    : 0
                })
              })
              this.networkInterfaceData.push(obj)
            })
          }
        })
    },
    getKeys (index) {
      return [
        { key: `net.if.speed[ifHighSpeed.${index}]`, dataIndex: 'speed' },
        { key: `net.if.in[ifHCInOctets.${index}]`, dataIndex: 'net_if_in' },
        { key: `net.if.out[ifHCOutOctets.${index}]`, dataIndex: 'net_if_out' }
      ]
    }
  }
}
</script>

<style lang="less">
.detail-drawer {
  .small-module-title{
    height: 35px;
    background: #E4F1FF;
    border-radius: 8px;
    color: #096DD9;
    padding: 0 16px;
    margin-bottom: 16px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

  .button-container {
    text-align: right;
    padding: 0 0 8px 0;

    a {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .active {
      color: #1890ff;
    }
  }

  .unit-card {
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
  }
  .ant-card {
    box-shadow: 0 0 8px #0000001a !important;
  }
}
</style>
